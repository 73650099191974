<template>
  <b-row class="statements-page">
    <b-col>
      <PageHeader :title="$t('general.referrals.title')" />

      <div class="referral-link-card mt-3">
        <div class="referral-link-card__column">
          <div class="referral-link-card__row">
            <div class="referral-link-card__title">
              <span
                class="referral-link-card__font referral-link-card__font--primary"
              >
                {{ $t("general.referrals.referralLink") }}
              </span>
            </div>

            <div class="referral-link-card__right">
              <span
                class="referral-link-card__font referral-link-card__font--addition"
              >
                {{ $t("general.referrals.referText") }}
              </span>
            </div>
          </div>
        </div>

        <button class="referral-link-card__link" role="button" @click="copy">
          <span class="referral-link-card__font referral-link-card__font--link">
            {{ link }}
          </span>
        </button>

        <div class="referral-link-card__footer">
          <i18n
            path="general.referrals.referDescription"
            tag="span"
            class="referral-link-card__font referral-link-card__font--description"
          >
            <template v-slot:percent>
              <span
                class="referral-link-card__font referral-link-card__font--description referral-link-card__font--accent"
              >
                10%
              </span>
            </template>
          </i18n>
        </div>
      </div>

      <InfinityScroll
        v-if="referrals.data && referrals.data.length"
        :items="referrals.data"
        :has-more="Boolean(referrals.next_page_url)"
        @rich-end="loadMore"
      >
        <template #default="{ item: card }">
          <ReferralsCard :card="card" />
        </template>
      </InfinityScroll>
    </b-col>
  </b-row>
</template>

<script>
import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";

import PageHeader from "@/layout/PageHeader.vue";

import { mapActions, mapGetters } from "vuex";
import InfinityScroll from "@/features/components/InfinityScroll.vue";
import ReferralsCard from "@/features/components/ReferralsCard.vue";
export default {
  name: "PageMyReferrals",
  components: {
    ReferralsCard,
    InfinityScroll,
    PageHeader,
  },
  mixins: [waitRequest, validationError],
  computed: {
    ...mapGetters({
      referrals: "partner/referrals",
      link: "partner/link",
    }),
  },
  data() {
    return {};
  },
  mounted() {
    this.initState();
  },
  methods: {
    ...mapActions({
      fetchStatements: "statements/fetchStatements",
      fetchLink: "partner/fetchLink",
      fetchReferrals: "partner/fetchReferrals",
    }),
    initState() {
      this.getReferrals().then(() => {
        this.fetchLink().catch(this.checkErrors);
      });
    },

    createQueryConfig(config) {
      return {
        page: this.referrals.current_page || 1,
        limit: this.referrals.per_page || 30,

        ...config,
      };
    },

    getReferrals() {
      return this.waitRequest(() => {
        return this.fetchReferrals().catch(this.checkErrors);
      });
    },

    loadMore() {
      const queryConfig = this.createQueryConfig({
        page: this.referrals.current_page + 1,
      });

      return this.waitRequest(() => {
        return this.fetchReferrals(queryConfig).catch(this.checkErrors);
      });
    },

    copy() {
      navigator.clipboard.writeText(this.link);

      this.$bvToast.toast(this.$t("general.copy"), {
        autoHideDelay: 2000,
        title: this.$t("general.success"),
        solid: true,
        toaster: "b-toaster-bottom-left flow-toast",
      });
    },
    async createPayout() {
      if (this.requestInProgress) return;
      this.$validator.errors.clear();

      const amount = this.amount.replace("$", "").replaceAll(",", "");
      return this.waitRequest(() => {
        return this.addPayout({ amount })
          .catch(this.checkErrors)
          .finally(() => {
            this.tab = this.tabs[1];
          });
      });
    },
  },
};
</script>

<style scoped lang="scss">
.referral-link-card {
  //border: 1px solid #e9e9e9;
  background: $app-blue;
  border-radius: 10px;
  padding: 15px 15px 16px 19px;

  &__column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__right {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__title {
    flex: 1;
  }

  &__font {
    font-family: var(--font-family);
    color: $white;

    &--primary {
      font-weight: 700;
      font-size: 12px;
    }

    &--addition,
    &--description {
      font-weight: 400;
      font-size: 9px;
    }

    &--link {
      font-weight: 400;
      font-size: 12px;
      color: $app-gray-19;
    }

    &--accent {
      font-weight: 700;
    }
  }

  &__button {
    width: auto;
    padding: 7px;
    border-radius: 100px;
    background: $app-blue;
    align-self: flex-end;
  }

  &__link {
    border-radius: 8px;
    width: auto;
    height: 23px;

    padding: 6px 29px 6px 8px;
    margin-top: 7px;
    background: $app-gray-25;
    justify-content: flex-start;
  }

  &__footer {
    margin-top: 5px;
  }
}

.statements-page {
  &__header {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: flex-end;
  }

  &__tabs {
    display: flex;
    flex-wrap: nowrap;
  }

  &__title {
    margin-top: 18px;
  }
  &__description {
    margin-top: 8px;
  }

  &__font {
    &--title {
      font-family: var(--font-family);
      font-weight: 500;
      font-size: 18px;
      color: $app-gray-26;
    }

    &--description {
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 14px;
      line-height: 129%;
      color: $app-gray-27;
    }
  }

  &__controls {
    margin-top: 8px;
  }

  &__hr {
    margin-left: em(-15);
    margin-right: em(-15);
    border-bottom: 1px solid $app-gray-2;
    margin-top: 14px;

    &--1 {
      margin-top: 14px;
    }
  }
}

.collections-header-button {
  &__button {
    width: em(30);
    text-align: center;
  }

  &__icon {
    color: $app-gray-17;
    width: em(19);
    height: em(19);

    &--plus {
      width: em(14);
      height: em(14);
    }
  }
}

.statements-tab {
  $parrent: &;
  flex: 1;
  text-align: center;

  &__item {
    padding-top: em(15);
    padding-bottom: em(17);
    border-bottom: 1px solid $app-gray-18;

    &--active {
      padding-bottom: em(16);
      border-bottom: 2px solid $app-black;

      #{$parrent}__icon,
      #{$parrent}__font {
        color: $app-black;
      }
    }
  }
  &__icon-wrapper {
    margin-right: 3px;
  }
  &__icon {
    color: $app-gray-18;
    width: 18px;
    height: 18px;
    min-width: 18px;
    min-height: 18px;
  }

  &__font {
    color: $app-gray-18;
    text-align: center;
    font-family: $font-default;
    font-style: normal;
    line-height: normal;
    font-weight: 600;
    font-size: 10px;

    &--active {
      color: $app-black;
      padding-bottom: em(16);
      border-bottom: 2px solid $app-black;
    }
  }
}

.statements-spoiler {
  $parent: &;
  display: flex;
  align-items: center;
  padding: 16px;
  border-radius: 6px 6px 6px 6px;
  background-color: $app-blue;

  &__buttons {
    //margin-top: 12px;
  }

  &__button {
    &:not(:first-of-type) {
      margin-top: 11px;
    }

    &--arrow {
      width: auto;
      margin-left: auto;
      margin-top: 0;

      &--open {
        #{$parent}__icon--arrow-to-top {
          transform: rotate(360deg);
          transition: $ease-out-cubic;
        }
      }
    }
  }

  &__font {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 13px;
    color: $white;
    flex: 1;
    white-space: nowrap;

    &--amount {
      font-family: var(--font-family);
      font-weight: 600;
      font-size: 16px;
      text-align: right;
      color: $white;
      margin-right: 13px;
    }
  }

  &__icon {
    color: $white;
    &--discount {
      width: 17px;
      height: 17px;
      min-width: 17px;
      min-height: 17px;
      margin-right: 6px;
    }

    &--arrow-to-top {
      transform: rotate(180deg);
      margin-left: auto;
      width: 15px;
      height: 15px;
    }
  }

  &--open {
    border-radius: 6px 6px 0 0;
  }
}

.spoiler-form {
  &__form {
    //margin-top: em(32);
    width: 100%;
    //margin-right: em(16);
    //margin-left: em(16);
  }

  &__hr {
    border-top: 1px solid #e8e8e8;
    height: 0px;
    margin-left: -7px;
    margin-right: -7px;
  }

  &__button {
    margin-top: em(12);
    //margin-right: em(15);
    margin-left: auto;
  }

  &__menu-button {
    width: 100%;
  }

  &__button-wrapper {
    display: flex;
  }

  &__input {
    margin-top: em(16);
    position: relative;
  }

  &__counter {
    position: absolute;
    top: 100%;
    left: 0;
  }

  &__field {
  }

  &__group-title {
    padding: em(21) 0 em(21) em(20);
  }

  &__field-name {
    margin-top: 12px;
  }

  &__font {
    &--field-name {
      font-size: em(14);
      font-weight: 500;
    }

    &--addition {
      font-family: $font-default;
      font-size: em(12);
      color: $app-font-color-placeholder;
      text-align: left;
    }

    &--group-title {
      font-family: $font-default;
      font-style: normal;
      line-height: normal;
      color: $black;
      font-size: em(18);
      font-weight: 500;
    }
  }

  &__icon {
    color: $app-gray-18;

    &--check {
      width: em(14);
      height: em(10);
    }

    &--false {
      width: em(15);
      height: em(15);
    }
  }
}

.spoiler-content {
  padding: 16px;
  border: 1px solid $app-gray-29;
  border-radius: 0 0 6px 6px;

  &__header {
    padding: 0 0 16px;
    display: flex;
    justify-content: space-between;
  }

  &__font {
    &--head {
      font-family: var(--font-family);
      font-weight: 600;
      font-size: 16px;
      color: $app-gray-33;
    }
  }

  &__icon-wrapper {
    margin-left: 6px;
    position: relative;
    bottom: 1px;
  }

  &__icon {
    &--info {
      color: $app-gray-33;
      width: 18px;
      height: 18px;
      min-width: 18px;
      min-height: 18px;
    }
  }
}

.info {
  display: flex;
  border: 1px solid $app-gray-29;
  border-radius: 6px;
  background-color: $black;

  &__icon-wrapper {
    padding: 7px 14px 9px;
  }

  &__text {
    padding: 11px 0 11px 0;
  }

  &__font {
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 14px;
    color: $white;

    &--anderline {
      text-decoration: underline;
      text-decoration-skip-ink: none;
    }
  }

  &__icon {
    width: 24px;
    height: 24px;
    min-height: 24px;
    min-width: 24px;
  }
}

.spoiler-input {
  &__error {
    position: absolute;
    top: 15px;
    display: inline-block;
    width: 100%;
    font-weight: normal;
    font-size: em(12);
    font-style: normal;
    line-height: 1.2;
    color: $red;
  }
}
</style>
