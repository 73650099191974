<template>
  <div class="referral-card">
    <div class="referral-card__header">
      <div class="referral-card__card">
        <div class="referral-card__image-wrapper">
          <img
            :src="avatarImage"
            :alt="`user avatar ${name}`"
            class="referral-card__image"
          />
        </div>

        <div class="referral-card__description">
          <div class="referral-card__title">
            <span
              v-if="name"
              class="referral-card__font referral-card__font--title"
            >
              {{ name }}
            </span>

            <span
              v-if="username"
              class="referral-card__font referral-card__font--name"
            >
              {{ username }}
            </span>
          </div>

          <div class="referral-card__info">
            <UiBadge v-if="joined" class="referral-card__badge">
              {{ joined }}
            </UiBadge>

            <UiBadge
              :theme="isVerified ? 'primary' : 'base'"
              class="referral-card__badge"
            >
              {{ verificationStatus }}
            </UiBadge>

            <UiBadge
              @click.native="chat"
              class="referral-card__badge"
              role="button"
            >
              <CIcon
                name="chat"
                path="subscriptions"
                class="referral-card__icon"
              />
              {{ $t("general.referralsCard.chat") }}
            </UiBadge>
          </div>
        </div>
      </div>
    </div>

    <div class="referral-card__footer">
      <div class="referral-card__item">
        <span class="referral-card__font referral-card__font--footer">
          {{ $t("general.referralsCard.earnings") }}
        </span>
        <span class="referral-card__font referral-card__font--accent">
          {{ earnings }}
        </span>
      </div>

      <div class="referral-card__item">
        <span class="referral-card__font referral-card__font--footer">
          {{ $t("general.referralsCard.commission") }}
        </span>
        <span class="referral-card__font referral-card__font--accent">
          {{ commission }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import UiBadge from "@/features/ui/common/UiBadge.vue";
import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";
import { currencyFormat } from "@/tools/helpers";
import CIcon from "@/features/ui/CIcon.vue";
import { mapGetters } from "vuex";
import { verificationStatuses } from "@/config/verificationStatuses";

export default {
  name: "ReferralsCard",
  components: { CIcon, UiBadge },
  mixins: [waitRequest, validationError],
  props: {
    card: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      settings: "settings/settings",
    }),
    verificationStatus() {
      const verificationStatuses = this.settings?.verification_statuses || [];
      const currentStatus = this.card?.referral?.verification_status || 0;

      return verificationStatuses[currentStatus];
    },

    isVerified() {
      return (
        this.card?.referral?.verification_status ===
        verificationStatuses.approved.id
      );
    },
    avatarImage() {
      return this.card?.referral?.avatar || "/mock/PFP.webp";
    },
    userId() {
      return this.card?.referral?.id;
    },
    name() {
      return this.card?.referral?.name || "Alex Hamin";
    },
    username() {
      return `@${this.card?.referral?.username || "alexhamin19"}`;
    },
    commission() {
      return `$${this.card?.commission || 0}`;
    },
    earnings() {
      return `$${this.card?.earnings || 0}`;
    },
    joined() {
      if (!this.card?.joined_at) return "";

      return `${this.$t("general.referralsCard.joined")} ${this.formatDate(
        this.card?.joined_at
      )}`;
    },
  },
  methods: {
    currencyFormat,

    formatDate( joinDate = '' ) {
      const date = new Date(joinDate)
      let options = { month: "numeric", day: "numeric", year: "2-digit" };

      return date?.toLocaleDateString("en-US", options);
    },

    chat() {
      this.$router.push('/messages/' + this.userId)
    },
  },
};
</script>

<style lang="scss" scoped>
.referral-card {
  border: 1.5px solid $app-gray-25;
  border-radius: 10px;
  width: 100%;
  padding: 16px 15px 19px;
  margin-top: 16px;

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__card {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__image-wrapper {
  }

  &__image {
    width: 42px;
    height: 42px;
    border-radius: 50%;
  }

  &__description {
    display: flex;
    flex-direction: column;
    margin-left: em(11);
  }

  &__title {
  }

  &__badge {
    margin-top: em(6);
  }

  &__font {
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 10px;
    line-height: 120%;

    &--title {
      font-weight: 500;
      font-size: 14px;
      color: $app-black;
      text-transform: uppercase;
    }

    &--name {
      font-weight: 400;
      font-size: 14px;
      color: $app-gray-18;
    }

    &--toggle {
      font-weight: 400;
      font-size: 12px;
      color: $app-gray-18;
    }

    &--footer {
      color: $app-gray-35;
    }

    &--accent {
      color: $black;
    }
  }

  &__badge {
  }

  &__info {
    display: inline-flex;
    align-items: center;
    gap: 8px;
  }

  &__icon {
    width: 10px;
    height: 10px;
    margin-right: 4px;
  }

  &__item {
    flex: 1;
    display: flex;
    justify-content: space-between;
    padding: 7px 9px 6px;
    border: 1.5px solid $app-gray-25;
    border-radius: 100px;
  }

  &__footer {
    margin-top: em(16);
    display: flex;
    align-items: center;
    gap: 20px;
  }

  &__toggle {
    margin-left: em(10);
    font-size: 50%; // change toggle default size
  }

  &__tooltip {
    padding: em(18) em(18) em(20);
  }

  &__button {
    color: $black;
    text-decoration: none;
    justify-content: flex-start;

    &:not(:first-child) {
      margin-top: em(15);
    }

    &:active {
      transform: none;
      filter: none;
    }
  }
}
</style>
